
import React from 'react';

// Custom components
import AccessDeniedComponent from '../../../components/AccessDeniedComponent/AccessDeniedComponent';

// UI library components
import Heading from '@react/react-spectrum/Heading';
import Button from '@react/react-spectrum/Button';
import Checkbox from '@react/react-spectrum/Checkbox'
import { ErrorResponse } from '../../../models/ErrorResponse';
import ErrorDialog from '../../../utils/Dialogs/ErrorDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import { createDefaultReseller, createDefaultCustomer, createDefaultOrder, createDefaultMembership, createDefaultTransfer } from '../../../providers/DefaultDataAPI';
import {generateDistributorId, requestAccess, provisionNewPartner, requestUserAccess, createDistributor} from '../../../providers/DistributorsAPI';
import {DistributorObject} from '../../../models/distributors/DistributorObject';

import { getAllCountries } from '../../../providers/CountryAPI';
import TextField from '@react/react-spectrum/Textfield'
import Link from '@react/react-spectrum/Link'
import { Grid } from '@material-ui/core';
import Select from '@react/react-spectrum/Select';
// Custom styling and utils
import './CreateDistributorPage.css';
import { getEnvironment, Environment } from '../../../config/EnvironmentConfig';
import { getDefaultAddressOptionsWithReason, getDefaultPriceRegion, getPriceRegionOptionsWithReason, getCurrencyOptionsWithReason, getPriceRegion, getDistributorTypeWithReason, getCreationDestinationWithReasonByEnv } from '../../service/SelectService';
import { getValidationState } from '../../../utils/FormUtils';
import { getCountriesInPriceRegion, getRegionsInCountry } from '../../../InternalPage/service/SelectService';
import ModifyContactsDialog from '../../../utils/Dialogs/ModifyContactsDialog';

// for form
// Custom Components
import AddressForm from '../../../templates/form/AddressForm';
import ContactForm from '../../../templates/form/ContactForm';
// Custom styling and utils
import { AddressObject } from '../../../models/memberships/Address';
import { ContactObject } from '../../../models/memberships/Contact';
import { CurrencyObject, CurrencyOption } from '../../../models/distributors/Currency';
import { CountryObject, CountriesResponse } from '../../../models/distributors/Country';

import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { checkIfAdobeUser } from '../../../service/ApiService';
import { createOrganization } from '../../../providers/BanyanAPI';
import { isWorldWideFlagDevEnabled, isWorldWideFlagEnabled, isTransferLGAFlagDevEnabled, isTransferLGAFlagEnabled } from '../../../config/FeatureFlags';

interface FullDistributorObject extends DistributorObject {
  invalid: boolean,
  isLoading: boolean,
  checkValid: boolean,
  dryRun: boolean
}

interface PostmanEnvValues {
    enabled: boolean;
    key: string;
    value: string;
}

interface PostmanEnvFile {
    name: string;
    values: Array<PostmanEnvValues>;
}

function getEmptyAddressObject() {
    return {
        country: '',
        region: '',
        city: '',
        addressLine1: '',
        addressLine2: '',
        postalCode: '',
        phoneNumber: ''
    };
}

function getEmptyContactObject() {
    return {
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
        type: 'ADMIN'
    };
}

function getEmptyCurrenciesObject() {
    return {
        priceRegion: '',
        currency: '',
    };
}

// TODO: Clean up the code here to make it more readable/reusable
function CreateDistributorPage() {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    var [errorResponse, setErrorResponse] = React.useState<ErrorResponse | undefined>();

    const [isLoadingProvisioning, setIsLoadingProvisioning] = React.useState(false);
    const [loadedData, setLoadedData] = React.useState(false);
    const [provisioningLoaded, setProvisioningLoaded] = React.useState(false);

    const [isLoadingCreation, setIsLoadingCreation] = React.useState(false);
    const [loadedCreation, setLoadedCreation] = React.useState(false);
    const [creationCompleted, setCreationCompleted] = React.useState(false);

    const [assignAPI, setAssignAPI] = React.useState(true);
    const [runProvisioning, setRunProvisioning] = React.useState(true);
    const [uploadEnvironment, setUploadEnvironment] = React.useState(true);

    // Checks if data was returned successfully 
    const [loadedCreatedOrg, setLoadedCreatedOrg] = React.useState(false);
    // Is turned true when the api call has been made to create organization. Starts the spinner
    const [isCreatingOrganization, setIsCreatingOrganization] = React.useState(false);
    // Shows a check or an x to show success or failure for organization created
    const [organizationCreated, setOrganizationCreated] = React.useState(false);
    // Boolean that disables the Create Using Global Admin button 
    const [creatingOrganization, setCreatingOrganization] = React.useState(true);

    const orderStatusList = ["1000", "1004", "1008"]
    var [creationStatus, setCreationStatus] = React.useState("");

    // Make dialog box saying script is complete
    var [newImsOrgId, setNewImsOrgId] = React.useState("");
    var [newDistributorId, setNewDistributorId] = React.useState("");
    var [newPartnerName, setNewPartnerName] = React.useState("");

    var [pKeyFile, setPKeyFiles] = React.useState("");
    var [postmanEnvFile, setPostmanEnvFiles] = React.useState<PostmanEnvFile>();
    var [resetPrivateKey, setPrivateKey] = React.useState("pkey");
    var [resetPostmanEnvKey, setPostmanEnvKey] = React.useState("envKey");
    const [downloadEnvironment, setCanDownload] = React.useState(true);

    let [selectDefaultAddress, setSelectDefaultAddress] = React.useState('');
    let [isDefault, setIsDefault] = React.useState(false);
    const [isAddressInvalid, setIsAddressInvalid] = React.useState(true);

    let [selectPriceRegion, setSelectPriceRegion] = React.useState("");
    let [selectCurrency, setSelectCurrency] = React.useState("");

    let [selectDistributorType, setSelectDistributorType] = React.useState("");
    let [selectCreationDestination, setSelectCreationDestination] = React.useState("");

    let [accessCbxSelected, setAccessCbxSelected] = React.useState(false);
    let [accessCbxDisabled, setAccessCbxDisabled] = React.useState(false);
    let [defaultCbxSelected, setDefaultCbxSelected] = React.useState(false);
    let [defaultCbxDisabled, setDefaultCbxDisabled] = React.useState(false);
    let [generateCbxSelected, setGenerateCbxSelected] = React.useState(false);
    let [generateCbxDisabled, setGenerateCbxDisabled] = React.useState(false);
    let [globalDistributorSelected, setGlobalDistributorSelected] = React.useState(false);
    let [lgaDistributorSelected, setLgaDistributorSelected] = React.useState(false);

    let [distributorIdDisabled, setDistributorIdDisabled] = React.useState(false);

    let [ecmContractDisabled, setEcmContractDisabled] = React.useState(true);

    let [allCountries, setAllCountries] = React.useState<CountryObject[]>([]);
    const [reducedCountryList, setReducedCountryList] = React.useState<CountryObject[]>([]);
    let [isCountriesDisabled, setIsCountriesDisabled] = React.useState(true);
    const [reducedRegionList, setReducedRegionList] = React.useState<string[]>([]);
    const [postalCodeRegex, setPostalCodeRegex] = React.useState("");
    const [selectedCountryObject, setSelectedCountryObject] = React.useState<CountryObject>();

    
    React.useEffect(() => {
        getAllCountries().then((countryResponse: CountriesResponse) => {
          if(countryResponse.httpstatus === 200) {
            setIsLoading(false);
            setIsError(false);
            var sortedCountryList = countryResponse.data.sort((a, b) => {
                return a.name.localeCompare(b.name, undefined, {
                  numeric: true,
                  sensitivity: 'base'
                });
            })
            setAllCountries(sortedCountryList)
          } else {
            setIsError(true);
            setIsLoading(false);
            setErrorResponse(countryResponse);
          }
        });
      }, []);

    var defaultDistributorObject : FullDistributorObject = {
        accountId: '',
        tenantId: '',
        ecmContractId: '',
        operationType: 'distributor',
        type: '',
        name: '',
        preferredLanguage: 'en-US',
        address: getEmptyAddressObject(),
        contacts: [getEmptyContactObject()],
        currencies: [getEmptyCurrenciesObject()],
        invalid: false,
        isLoading: false,
        checkValid: false,
        dryRun: true
    }
    var [distributorObject, setDistributorObject] = React.useState(defaultDistributorObject);

    const nonRequiredFields = ['phoneNumber', 'addressLine2'];

    const validateObject = (obj: object | undefined): boolean => {
        let isValid = true;
        if (obj) {
          Object.entries(obj).forEach(([key, val]) => {
            if (typeof val === 'string' && nonRequiredFields.indexOf(key) === -1 && val.trim() === '') {
                distributorObject.invalid = true
                isValid = false;
            }
          });
        }
        return isValid;
      };

    const validateArray = (objArr: object[]): boolean => {
        for (var obj of objArr) {
          let validObject = validateObject(obj);
          if (!validObject) {
            return false;
          }
        }
        return true;
    };

    const updateValidAddress = (isValidAddress: boolean) => {
        setIsAddressInvalid(isValidAddress)
        return
    }

    const clearCountryData = () => {
        setSelectCurrency("")
        setSelectPriceRegion("")
        selectPriceRegion = ""
        selectCurrency = ""
        distributorObject.address = getEmptyAddressObject()
        distributorObject.currencies = [getEmptyCurrenciesObject()]
        setReducedCountryList(getCountryList())
        setReducedRegionList([])
        setSelectDefaultAddress("")
        setIsDefault(false)
        checkCreatingOrganization()
        return
    }

    const updateDefaultAddress = (value: string | string[]) => {
        var addressObject = JSON.parse(value.toString()) as AddressObject

        if(addressObject.country !== '') {
            isDefault = true
            setIsDefault(true)

            var currencyObject = getDefaultPriceRegion(addressObject.country)

            var priceRegion = getPriceRegion(currencyObject.priceRegion)
            selectPriceRegion = JSON.stringify(priceRegion)
            setSelectPriceRegion(JSON.stringify(priceRegion))

            setSelectCurrency(currencyObject.currency)
            selectCurrency = currencyObject.currency
        } else {
            isDefault = false
            setIsDefault(false)
        }

        setSelectDefaultAddress(value.toString())
        selectDefaultAddress = value.toString()
        updateAddressState(JSON.parse(selectDefaultAddress) as AddressObject)
    }

    const updateAddressState = (address: AddressObject) => {
        distributorObject.address = address;
        setDistributorObject(distributorObject)

        if(selectDefaultAddress) {
            if (selectDefaultAddress !== JSON.stringify(getEmptyAddressObject())) {
                var defaultCurrencyInfo = JSON.parse(JSON.stringify(getDefaultPriceRegion(address.country))) as CurrencyObject
                distributorObject.currencies[0] = {currency: defaultCurrencyInfo.currency, priceRegion: defaultCurrencyInfo.priceRegion}
            }
        } else {

            if(selectPriceRegion !== '') {
                setReducedCountryList(getReducedCountries())
            }
            if(distributorObject.address.country !== "") {
                setReducedRegionList(getReducedRegions())
            }
            

            if(selectPriceRegion === "") {
                setIsCountriesDisabled(true)
            } else {
                setIsCountriesDisabled(false)
            }
        }
        return
    };

    const updatePriceRegion = (priceRegion: string | string[]) => {
        setLoadedCreatedOrg(false)
        var defaultCurrencyInfo = JSON.parse(priceRegion.toString()) as CurrencyOption
        distributorObject.currencies[0].priceRegion = defaultCurrencyInfo.priceRegion
        setSelectPriceRegion(priceRegion.toString())
        selectPriceRegion = priceRegion.toString()
        setSelectCurrency(defaultCurrencyInfo.currencies[0])
        selectCurrency = defaultCurrencyInfo.currencies[0]
        distributorObject.currencies[0].currency = defaultCurrencyInfo.currencies[0]
        setReducedCountryList(getReducedCountries())
        setReducedRegionList([])
        setDistributorObject(distributorObject)
        checkCreatingOrganization()
    };

    const updateCountry = (country: string | string[]) => {
        setLoadedCreatedOrg(false)
        distributorObject.address.country = country.toString()
        updateAddressState(distributorObject.address);
        checkCreatingOrganization()
      };
    

    const getReducedCountries = () => {
        var priceRegionObject = JSON.parse(selectPriceRegion.toString()) as CurrencyOption
        var results = allCountries.filter(country => ~[priceRegionObject.priceRegion].indexOf(country.pricelist_region));
        var sortedCountryList = results.sort((a, b) => {
            return a.name.localeCompare(b.name, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
        })
        return sortedCountryList
    }

    const getReducedRegions = () => {
        var results: CountryObject[] = allCountries.filter(ctry => ~[distributorObject.address.country].indexOf(ctry.code))

        var priceRegion = getPriceRegion(results[0].pricelist_region)
        updatePriceRegion(JSON.stringify(priceRegion))

        setSelectedCountryObject(results[0])        
        setPostalCodeRegex(results[0].postal_code_format_regex)
        var sortedRegionsList = results[0].regions.sort((a, b) => {
            return a.localeCompare(b, undefined, {
              numeric: true,
              sensitivity: 'base'
            });
        })
        return sortedRegionsList
    }

    const updateCurrency = (currency: string | string[]) => {
        distributorObject.currencies[0].currency = currency.toString()
        setSelectCurrency(currency.toString())
        selectCurrency = currency.toString()
    };

    const updateContacts = (newContacts: FullDistributorObject) => {

        let contacts = [...distributorObject.contacts];
        distributorObject.contacts = contacts;
    };

    const updateContactState = (contact: ContactObject, index: number) => {
        let contacts = [...distributorObject.contacts];
        distributorObject.contacts = contacts;
    };

    const deleteContact = (index: number) => {
        let contacts = [...distributorObject.contacts];
        contacts.splice(index, 1);
        distributorObject.contacts = contacts;
    };

    const updateDistributorType = (distributorType: string | string[]) => {
        distributorObject.type = distributorType.toString().trim()
        setSelectDistributorType(distributorType.toString().trim())
        selectDistributorType = distributorType.toString().trim()
    };

    const updateCreationDestination = (destination: string | string[]) => {
        destination = destination.toString().trim()
        setSelectCreationDestination(destination)
        selectCreationDestination = destination
        setLoadedCreatedOrg(false)
        checkCreatingOrganization()
        switch (destination) {
          case "stage":
            setAccessCbxSelected(false)
            setAccessCbxDisabled(true)
            setDefaultCbxDisabled(true)
            setDefaultCbxSelected(false)
            setEcmContractDisabled(false)
            setGenerateCbxDisabled(false)
            setGenerateCbxSelected(false)
            setDistributorIdDisabled(false)
            break;
          case "prestage":
            setAccessCbxDisabled(false)
            setAccessCbxSelected(true)
            setDefaultCbxDisabled(false)
            setDefaultCbxSelected(true)
            setEcmContractDisabled(true)
            setGenerateCbxDisabled(false)
            setGenerateCbxSelected(false)
            setDistributorIdDisabled(false)
            break;
          case "dev":
            setAccessCbxDisabled(false)
            setAccessCbxSelected(true)
            setDefaultCbxDisabled(false)
            setEcmContractDisabled(true)
            setGenerateCbxDisabled(false)
            setGenerateCbxSelected(false)
            setDistributorIdDisabled(false)
            break;
          default:
            break;
        }
    };

    const handleDownloadNewPostmanEnvironment = () => {
        const fileData = JSON.stringify(postmanEnvFile);
        const blob = new Blob([fileData], {type: "text/plain"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = newPartnerName+'.postman_environment.json';
        link.href = url;
        link.click();
    }

    const handleUpdatePostmanEnvironment = () => {

        if( postmanEnvFile) {
            if (newDistributorId.trim() !== '' && newDistributorId.trim().length > 3 && newPartnerName.trim() !== '' ) {
                setCanDownload(false)

                let cpapiUrl : string;
                if (selectCreationDestination === "prestage") {
                  cpapiUrl = "https://partnersandbox-stage.adobe.io";
                } else {
                  cpapiUrl = "https://partners-"+selectCreationDestination+".adobe.io";
                }

                postmanEnvFile.name = newPartnerName
                postmanEnvFile.values.push({
                    value: newDistributorId ,
                    key: "distributorId",
                    enabled: true
                    });
                postmanEnvFile.values.push({
                    value: cpapiUrl,
                    key: "baseUrl",
                    enabled: true
                    });
                setPostmanEnvKey(Date.now().toString()+"1")
            } else {
                let error = {httpstatus: 500, message: "Empty Fields", reason: "Name and/or Distributor ID fields are invalid."}
                setErrorResponse(error)
                setIsError(true)
            }
        } else {
            let error = {httpstatus: 500, message: "Missing Files", reason: "Missing Private Key file or Postman File."}
            setErrorResponse(error)
            setIsError(true)
        }
    }

    const handlePostmanEnvChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUploadEnvironment(false)
        if( e.target.files ) {
            const fileReader = new FileReader();
            fileReader.readAsText(e.target.files[0], "UTF-8");
            fileReader.onload = e => {
                if( e.target ) {
                    // console.log("e.target.result", e.target.result);
                    if( e.target.result) {
                        var postmanEnvFileJSON = JSON.parse(e.target.result.toString());
                        setPostmanEnvFiles(postmanEnvFileJSON)
                    }
                }
            };
        }
    };

    const handleCreateAccessRequest = async (distObj : DistributorObject) => {

        let userAccessRequestBody = {
          emails: distObj.contacts.map(contact => contact.email),
          distributors: [{
              distributorId : distObj.accountId,
              imsOrgId : distObj.tenantId,
              distributorName : distObj.name
          }]
        };
        let userAccessBodyJson = JSON.stringify(userAccessRequestBody);
        // console.log(userAccessBodyJson);

        return new Promise(function(resolve, reject) {
            requestAccess(userAccessBodyJson, distObj.accountId).then((response : boolean) => {
                if(response) {
                  // TODO: Access Request was successful. Handle response
                } else {
                    setIsError(true)
                    errorResponse = {
                      message : 'Failed to grant portal access',
                      httpstatus : 400,
                      reason : ''
                    }
                    setErrorResponse(errorResponse)
                    setCreationCompleted(false)
                }
                resolve(response)
            });
        })
    }


    const handleCreateUserAccessRequest = async (distObj : DistributorObject) => {

        let userAccessRequestBody = {
            contactList: distObj.contacts,
            distributor: {
                distributorId : distObj.accountId,
                imsOrgId : distObj.tenantId,
                distributorName : distObj.name
            }
        };
        let userAccessBodyJson = JSON.stringify(userAccessRequestBody);
        // console.log(userAccessBodyJson);

        return new Promise(function(resolve, reject) {
            requestUserAccess(userAccessBodyJson, distObj.accountId).then((response : boolean) => {
                if(response) {
                  // TODO: Access Request was successful. Handle response
                } else {
                    setIsError(true)
                    errorResponse = {
                      message : 'Failed to grant portal access',
                      httpstatus : 400,
                      reason : ''
                    }
                    setErrorResponse(errorResponse)
                    setCreationCompleted(false)
                }
                resolve(response)
            });
        })
    }

    const handleCheckCreateDistributorId = () => {
        setLoadedCreation(false)
        setIsLoadingCreation(true)

        if(generateCbxSelected === true) {
            // setIsGeneratingDistributorId(true)
            let generateDistributorIdBody = {
                newDistributorObject: distributorObject,
                destination: selectCreationDestination.toUpperCase()
            };
            creationStatus = "Creating distributor ID..."
            generateDistributorId(generateDistributorIdBody).then((response) => {
                // console.log(response)
                // setIsGeneratingDistributorId(false)
                // setGeneratedDistributorId(true)
                if(response.httpstatus === 200 || response.httpstatus === 201) {
                    distributorObject.accountId = response.distributorId
                    setNewDistributorId(distributorObject.accountId)
                    // setDistributorIdGenerated(true)
                    handleCreateDistributor()
                } else {
                    // setDistributorIdGenerated(false)
                    setIsError(true)
                    setErrorResponse(response)
                }
            });
        } else {
            handleCreateDistributor()
        }
    };

    const handleCreateDistributor = () => {

        setLoadedCreation(false)
        creationStatus = ""
        setCreationStatus(creationStatus)
        distributorObject.contacts[0].type = "ADMIN"

        let tenantIdEnd = distributorObject.tenantId.trim().substr(distributorObject.tenantId.trim().length - 9).toLowerCase()

        distributorObject.checkValid = true;
        let validFields = distributorObject.name.trim() !== '' && distributorObject.accountId.trim() !== '' && distributorObject.accountId.trim().length > 3 && distributorObject.tenantId.trim() !== '' && tenantIdEnd === "@adobeorg";
        let distributorTypeFields = selectCreationDestination.trim() !== '' && distributorObject.type.trim() !== '';
        let validAddress = distributorObject.address ? validateObject(distributorObject.address) : true;
        let validArrays = validateArray(distributorObject.contacts) && validateArray(distributorObject.currencies);
        let isValid = validFields && validArrays && validAddress;

        // console.log(distributorObject)
        if (validFields) {
            if (validAddress) {
                if (validArrays) {
                    if(distributorTypeFields) {
                        if (isValid) {
                            distributorObject.invalid = false
                            setIsLoadingCreation(true)

                            if(generateCbxSelected) {
                                creationStatus = creationStatus + "Created distributor ID."
                                creationStatus = creationStatus + "\nCreating distributor..."
                            } else {
                                creationStatus = creationStatus + "Creating distributor..."
                            }
                            setCreationStatus(creationStatus)

                            distributorObject.isLoading = true;

                            const createDistributorBody = {
                                newDistributorObject: {
                                  ...distributorObject,
                                  ...globalDistributorSelected
                                    ? { globalSalesEnabled: globalDistributorSelected } : {},
                                  ...lgaDistributorSelected
                                     ? { isLgaEnabled: lgaDistributorSelected } : {}
                                },
                                destination: selectCreationDestination.toUpperCase()
                              };
                            createDistributor(createDistributorBody).then(async (response) => {
                                // console.log(response)
                                if(response.httpstatus === 200 || response.httpstatus === 201) {
                                    creationStatus = creationStatus + "\nDistributor Created in " + selectCreationDestination + " environment..."
                                    setCreationStatus(creationStatus)
                                    setIsError(false)

                                    if (accessCbxSelected) {
                                      await handleCreateAccessRequest(distributorObject)
                                      await handleCreateUserAccessRequest(distributorObject)
                                    }

                                    if(defaultCbxSelected) {
                                        var defaultSet = {
                                            accountId : distributorObject.accountId,
                                            tenantId : distributorObject.tenantId,
                                            resellerId : "",
                                            customerId : "",
                                            orderStatus: "",
                                            membershipId : ""
                                        }

                                        handleCreateDefaultReseller(defaultSet).then((resellerResponse: any) => {
                                            defaultSet.resellerId = resellerResponse.resellerId
                                            handleCreateDefaultCustomer(defaultSet).then((customerResponse: any) => {
                                                defaultSet.customerId = customerResponse.customerId
                                                handleCreateDefaultOrder(defaultSet).then((orderResponse: any) => {
                                                    handleCreateDefaultMembership(defaultSet).then((membershipResponse: any) => {
                                                        defaultSet.membershipId = membershipResponse.membershipId
                                                        handleCreateDefaultTransfer(defaultSet).then( response => {
                                                            setCreationCompleted(true)
                                                            creationStatus = creationStatus + "\nDistributor & Default Records Created Successfully."
                                                            setCreationStatus(creationStatus)
                                                            setIsLoadingCreation(false)
                                                            setLoadedCreation(true);
                                                        })
                                                    })
                                                })
                                            })
                                        })
                                    } else {
                                        setCreationCompleted(true)
                                        creationStatus = creationStatus + "\nDistributor Created in " + selectCreationDestination + " environment"
                                        setCreationStatus(creationStatus)
                                        setIsLoadingCreation(false)
                                        setLoadedCreation(true);
                                    }
                                } else {
                                    setIsLoadingCreation(false)
                                    setCreationCompleted(false)
                                    setIsError(true)
                                    let error = {httpstatus: response.httpstatus, message: response.message, reason: ""}
                                    setErrorResponse(error)
                                }
                            });
                        } else {
                            let error = {httpstatus: 500, message: "Error in Form" , reason: "Invalid entry in form."}
                            setErrorResponse(error)
                            setIsError(true)
                            setIsLoadingCreation(false)
                        }
                    } else {
                        let error = {httpstatus: 500, message: "Error in DistributorType" , reason: "Error in Distrbutor Type or Distributor Destination fields.."}
                        setErrorResponse(error)
                        setIsError(true)
                        setIsLoadingCreation(false)
                    }
                } else {
                    let error = {httpstatus: 500, message: "Error in Contact or Currency" , reason: "Error in Contact or Currency fields."}
                    setErrorResponse(error)
                    setIsError(true)
                    setIsLoadingCreation(false)
                }
            } else {
                let error = {httpstatus: 500, message: "Address Error" , reason: "Error in address fields."}
                setErrorResponse(error)
                setIsError(true)
                setIsLoadingCreation(false)
            }
        } else {
            let error = {httpstatus: 500, message: "Distributor Info Error" , reason: "Error in Name, Ims Org ID, or Distributor ID"}
            setErrorResponse(error)
            setIsError(true)
            setIsLoadingCreation(false)
        }
    }


    const handleCreateDefaultReseller = (defaultSet: any) => {
        creationStatus = creationStatus + "\nCreating Default Records (1/5). Creating Default Reseller..."
        setCreationStatus(creationStatus)
        return new Promise(function(resolve, reject) {
            createDefaultReseller(defaultSet).then((response) => {
                if(response.httpstatus === 200 || response.httpstatus === 201) {
                    creationStatus = creationStatus + "\nCreating Default Records (1/5). The reseller record was created successfully."
                    setCreationStatus(creationStatus)
                    defaultSet.resellerId = response.completed.resellerId
                } else {
                    setIsError(true)
                    errorResponse = response.completed
                    errorResponse!.httpstatus = response.httpstatus
                    setErrorResponse(errorResponse)
                    setCreationCompleted(false)
                }
                resolve(defaultSet)
            });
        })
    };

    const handleCreateDefaultCustomer = (defaultSet: any) => {
        creationStatus = creationStatus + "\nCreating Default Records (2/5). Creating Default Customer..."
        setCreationStatus(creationStatus)
        return new Promise(function(resolve, reject) {

            createDefaultCustomer(defaultSet).then((response) => {
                if(response.httpstatus === 200 || response.httpstatus === 201) {
                    creationStatus = creationStatus + "\nCreating Default Records (2/5). The customer record was created successfully."
                    setCreationStatus(creationStatus)
                    defaultSet.customerId = response.completed.customerId
                } else {
                    setIsError(true)
                    errorResponse = response.completed
                    errorResponse!.httpstatus = response.httpstatus
                    setErrorResponse(errorResponse)
                    setCreationCompleted(false)
                }
                resolve(defaultSet)
            });
        })
    };

    const handleCreateDefaultOrder = (defaultSet: any) => {
        creationStatus = creationStatus + "\nCreating Default Records (3/5). This may take while. Creating Default Orders...."
        setCreationStatus(creationStatus)
        return new Promise(function(resolve, reject) {
            let promise = new Promise((resolve, reject) => resolve())
            orderStatusList.forEach( (statusCode, index) => {
                promise = promise.then(() => {
                    defaultSet.orderStatus = statusCode
                    return createDefaultOrder(defaultSet).then((response) => {
                        if((response.httpstatus === 200 || response.httpstatus === 201) && response.completed.lineItems) {
                            creationStatus = creationStatus + "\nCreating Default Records (3/5). This might take a while. Created default orders ("+(index+1)+"/"+orderStatusList.length+")..."
                            setCreationStatus(creationStatus)
                            if(statusCode == "1008") {
                                creationStatus = creationStatus + "\nCreating Default Records (3/5). The order records were created successfully."
                                setCreationStatus(creationStatus)
                                resolve(defaultSet)
                            }
                        } else {
                            setIsError(true)
                            errorResponse = response.completed
                            errorResponse!.httpstatus = response.httpstatus
                            setErrorResponse(errorResponse)
                            setCreationCompleted(false)
                            resolve(defaultSet)
                        }
                    });
                })
            })
        })
    };

    const handleCreateDefaultMembership = (defaultSet: any) => {
        creationStatus = creationStatus + "\nCreating Default Records (4/5). Creating Default Membership..."
        setCreationStatus(creationStatus)
        return new Promise(function(resolve, reject) {

            createDefaultMembership(defaultSet).then((response) => {
                if(response.httpstatus === 200 || response.httpstatus === 201) {
                    creationStatus = creationStatus + "\nCreating Default Records (4/5). The membership record was created successfully."
                    setCreationStatus(creationStatus)
                    defaultSet.membershipId = response.completed.membershipId
                } else {
                    setIsError(true)
                    errorResponse = response.completed
                    errorResponse!.httpstatus = response.httpstatus
                    setErrorResponse(errorResponse)
                    setCreationCompleted(false)
                }
                resolve(defaultSet)
            });
        })
    };

    const handleCreateDefaultTransfer = (defaultSet: any) => {
        creationStatus = creationStatus + "\nCreating Default Records (5/5). Creating Default Transfer..."
        setCreationStatus(creationStatus)
        return new Promise(function(resolve, reject) {

            createDefaultTransfer(defaultSet).then((response) => {
                if(response.httpstatus === 200 || response.httpstatus === 201) {
                    creationStatus = creationStatus + "\nCreating Default Records (5/5). The transfer record was created successfully."
                    setCreationStatus(creationStatus)
                    defaultSet.membershipId = ""
                } else {
                    setIsError(true)
                    errorResponse = response.completed
                    errorResponse!.httpstatus = response.httpstatus
                    setErrorResponse(errorResponse)
                    defaultSet.membershipId = ""
                    setCreationCompleted(false)
                }
                resolve(defaultSet)
            });
        })
    };


    const handleProvisionNewPartner = () => {
        if(distributorObject.tenantId.trim().toLowerCase().split('@').pop() === "adobeorg" && distributorObject.tenantId.trim() !== '') {
            var regex = /[ !#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

            if (!regex.test(distributorObject.tenantId.trim())) {
                // window.open("https://partner-api-provisioning.ci.corp.adobe.com/", '_blank');
                setIsLoadingProvisioning(true)
                let provisionRequestBody = {
                    partnerImsOrgId: newImsOrgId,
                };
                let provisionRequestBodyJson = JSON.stringify(provisionRequestBody);
                provisionNewPartner(provisionRequestBodyJson).then((response) => {
                    setIsLoadingProvisioning(false)
                    setLoadedData(true);
                    if(response.httpstatus === 200 || response.httpstatus === 201) {
                        setIsError(false)
                        setAssignAPI(false)
                        setProvisioningLoaded(true)
                    } else {
                        setProvisioningLoaded(false)
                        setAssignAPI(true)
                        setIsError(true)
                        errorResponse!.message = "Failed to provision partner"
                        errorResponse!.httpstatus = response.httpstatus
                        setErrorResponse(errorResponse)
                    }
                });
            }
        } else {
            let error = {httpstatus: 500, message: "Incorrect IMS Org ID format.", reason: "The field may be blank or missing adobeorg suffix."}
            setErrorResponse(error)
            setIsError(true)

        }
    };

    const handleCreateNewOrganization = () => {
        if(distributorObject.address.country !== '') {
            if (distributorObject.name.trim() !== '') {
                // window.open("https://partner-api-provisioning.ci.corp.adobe.com/", '_blank');
                setIsCreatingOrganization(true)
                let orgCreationRequestBody = {
                    countryCode: distributorObject.address.country,
                    newOrganizationName: distributorObject.name,
                    destination: selectCreationDestination.toUpperCase()
                };
                let orgCreationRequestBodyJson = JSON.stringify(orgCreationRequestBody);
                createOrganization(orgCreationRequestBodyJson).then((response) => {
                    // console.log(response)
                    setIsCreatingOrganization(false)
                    setLoadedCreatedOrg(true)
                    if(response.httpstatus === 200 || response.httpstatus === 201) {
                        setIsError(false)
                        handleIMSOrgID(response.data.id)
                        setOrganizationCreated(true)
                    } else {
                        setOrganizationCreated(false)
                        setIsError(true)
                        errorResponse!.message = "Failed to create the organization."
                        errorResponse!.httpstatus = response.httpstatus
                        setErrorResponse(errorResponse)
                    }
                });
            }  else {
                let error = {httpstatus: 500, message: "Incorrect Organization Name Inputted.", reason: "The field may be blank."}
                setErrorResponse(error)
                setIsError(true)
    
            }
        } else {
            let error = {httpstatus: 500, message: "Incorrect Country Selected.", reason: "The field may be blank."}
            setErrorResponse(error)
            setIsError(true)

        }
    };

    const handleDismissErrorDialog = () => {
        if(errorResponse?.httpstatus === 401) {
            sessionStorage.clear();
            window.location.reload();
        } else {
            setIsError(false)
        }
    };

    const handleIMSOrgID = (value: string) => {
        setNewImsOrgId(value);
        distributorObject.tenantId = value; 
        setLoadedData(false);
        setProvisioningLoaded(false)

        if(distributorObject.tenantId.trim().toLowerCase().split('@').pop() === "adobeorg") {
            setRunProvisioning(false)
        } else {
            setRunProvisioning(true)
        }
    }

    const checkCreatingOrganization = () => {
        if(distributorObject.address.country === '' || distributorObject.name.trim() === '' || selectCreationDestination === '') {
            setCreatingOrganization(true)
        } else {
            setCreatingOrganization(false)
        }
    }

    const handleDistributorName = (value: string) => {
      setNewPartnerName(value)
      setLoadedCreatedOrg(false)
      distributorObject.name = value;
      checkCreatingOrganization()
    }

    const getCountryList = () => {

        if(selectPriceRegion === '') {
            return allCountries
        } else {
            return reducedCountryList
        }
    }

    const [isModifyContactsOpen, setIsModifyContactsOpen] = React.useState(false);

    function handleDismissModifyContactsDialog() {
        setIsModifyContactsOpen(false)

    }

    const ValidatedApp = (
        <div>
            {
                (isModifyContactsOpen) ? <ModifyContactsDialog
                    isOpen={isModifyContactsOpen}
                    distributorObject={distributorObject}
                    handleDismissModifyContactsDialog={() => handleDismissModifyContactsDialog()}
                    updateContacts={(contacts) => updateContacts(contacts)}
                /> : null
            }

            { (errorResponse && isError) ? <ErrorDialog
                isError={true}
                status={errorResponse.httpstatus}
                reason={errorResponse.reason}
                message={errorResponse.message}
                handleDismissErrorDialog={handleDismissErrorDialog}
                />
                : null }

            <div className='settings-heading-container new-line'>
                <Heading variant='subtitle1'>Create Distributor</Heading>
                <Heading variant='subtitle3'>{getEnvironment() === Environment.DEV ? "Create a distributor in internal dev or stage environment." : "Create a distributor in the partner sandbox."}</Heading>
                <br/>
                <br />
                {isLoading ? (
                <CircularProgress size={20} />
                ) :
                <div>
                    <Grid container spacing={4}>
                        <Grid item md={4} xs={6}>
                            <Heading variant='subtitle2'>STEP 1: Create IMS Organization</Heading>
                            <i>Create IMS Organization for distributor.</i>
                            <br />
                            <br />
                            <Grid container spacing={3}>
                                <Grid item xs={3}>
                                    Price Region
                                    <br />
                                    <Select
                                        aria-label='Price Region'
                                        value={selectPriceRegion}
                                        disabled={isDefault}
                                        options={getPriceRegionOptionsWithReason()}
                                        onSelect={(value) => updatePriceRegion(value)}
                                    />
                                    <br />
                                    <br/>
                                </Grid>
                                <Grid item xs={5}>
                                    Country (2-char ISO)
                                    <br />
                                    <Select
                                        aria-label='Country'
                                        value={distributorObject.address.country}
                                        defaultValue={distributorObject.address.country}
                                        disabled={isDefault}
                                        options={getCountriesInPriceRegion(getCountryList())}
                                        onSelect={(value) => updateCountry(value)}
                                    />
                                    {/* disabled={isCountriesDisabled} options={getCountriesInPriceRegion(reducedCountryList)} */}
                                    <Link onClick={(event) => clearCountryData()}><span className="clear-link">Clear</span></Link>
                                    <br />
                                    <br />
                                </Grid>
                            </Grid>
                            <Grid container spacing={3}>
                                <Grid item xs={5}>
                                    <b>Distributor Environment</b>
                                    <br />
                                    <Select
                                    defaultValue={selectCreationDestination}
                                    value={selectCreationDestination}
                                    options={getCreationDestinationWithReasonByEnv(getEnvironment())}
                                    onSelect={(value) => updateCreationDestination(value)}
                                    className='distributor-dropdown'
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <b>Distributor Name</b>
                                    <br />
                                    <TextField
                                        onChange= {value => handleDistributorName(value)}
                                        placeholder="Distributor Name"
                                        value={newPartnerName}
                                        validationState={getValidationState(distributorObject.checkValid, distributorObject.name)}
                                    ></TextField>
                                </Grid>
                            </Grid>
                            <br/>
                            <Button 
                                disabled={creatingOrganization} 
                                onClick={event => handleCreateNewOrganization()}>
                                Create Using Global Admin Console
                            </Button>
                            {isCreatingOrganization ? (
                                    <CircularProgress size={20} />
                                ) : [
                                    loadedCreatedOrg ? (
                                        [ organizationCreated ? <CheckIcon className="success-color"/> : <ClearIcon color="secondary" /> ]
                                    ) : null
                            ]}
                            <br />
                            <br />
                            <Heading variant='subtitle2'>STEP 2: Provision CPAPI Distributor Product</Heading>
                            <Heading variant='subtitle3'>Only for CPAPI access</Heading>
                            <i>Run script to provision partner the CPAPI Distributor Product, giving access to create credentials.</i>
                            <br />
                            <br />
                            <TextField
                                className="ims-org-textfield"
                                onChange= {value => handleIMSOrgID(value) }
                                placeholder="Enter New Partner's IMS Org ID"
                                value={newImsOrgId}
                                validationState={getValidationState(distributorObject.checkValid, distributorObject.tenantId)}
                            ></TextField>
                            <br />
                            <br />
                            <Button disabled={runProvisioning} onClick={event => handleProvisionNewPartner()}>
                                Run Provisioning Script
                            </Button>
                            {isLoadingProvisioning ? (
                                    <CircularProgress size={20} />
                                ) : [
                                    loadedData ? (
                                        [ provisioningLoaded ? <CheckIcon className="success-color"/> : <ClearIcon color="secondary" /> ]
                                    ) : null
                            ]}

                            <br />
                            <br />
                            <Heading variant='subtitle2'>STEP 3: Create Adobe I/O Project and Credentials</Heading>
                            <Heading variant='subtitle3'>Only for CPAPI access</Heading>
                            <i>Select org, create a project, and subscribe to:
                              <ul>
                                <li>Commerce Partner API (Dev/Stage) - Internal</li>
                                <li>Commerce Partner API Sandbox (Prestage) - Partners</li>
                              </ul>
                              <b>DO NOT OPEN BEFORE PROVISIONING.</b>
                            </i>
                            <br />
                            <br />
                            <Link href="https://console-stage.adobe.io/" target="_blank"><Button disabled={assignAPI || runProvisioning}>
                                Open Developer Console
                            </Button> </Link>
                            <br />
                            <br />
                        </Grid>

                        <Grid container md={5} >
                            <Grid md={12}>
                                <Heading variant='subtitle2'>STEP 4: Create Distributor Record</Heading>
                                <i>Create Distributor, give access, and create initial default set of data for partner API use</i>
                            </Grid>
                            <Grid container md={10} spacing={3} >
                                <Grid item md={4}>
                                    <br />
                                    <b>Distributor Info</b>
                                    <br />
                                    Name
                                    <br />
                                    <TextField
                                        onChange= {value => handleDistributorName(value)}
                                        placeholder="Distributor Name"
                                        value={newPartnerName}
                                        validationState={getValidationState(distributorObject.checkValid, distributorObject.name)}
                                    ></TextField>
                                    <br />
                                </Grid>
                                <Grid item md={4}>
                                    <br />
                                    <br />
                                    Ims Org ID
                                    <br />
                                    <TextField
                                        onChange= {value => handleIMSOrgID(value) }
                                        value={newImsOrgId}
                                        validationState={getValidationState(distributorObject.checkValid, distributorObject.tenantId)}
                                    ></TextField>
                                    <br />
                                </Grid>
                                <Grid item md={4}>
                                    <br />
                                    <br />
                                    Distributor ID
                                    <br />
                                    <TextField
                                        onChange= {value => {setNewDistributorId(value.trim()); distributorObject.accountId = value.trim()}}
                                        value={newDistributorId}
                                        validationState={getValidationState(distributorObject.checkValid, distributorObject.accountId)}
                                        disabled={distributorIdDisabled}
                                    ></TextField>
                                    <br />
                                </Grid>
                            </Grid>
                            <Grid container md={10} spacing={3} >
                                <Grid item md={4}>


                                  <br />
                                  <b>Distributor Environment</b>
                                  <br />
                                  <Select
                                    aria-label='Distributor Environment'
                                    defaultValue={selectCreationDestination}
                                    value={selectCreationDestination}
                                    options={getCreationDestinationWithReasonByEnv(getEnvironment())}
                                    onSelect={(value) => updateCreationDestination(value)}
                                    className='distributor-dropdown'
                                  />
                                  <br />
                                  <br />
                                  <b>Distributor Type</b>
                                  <br />
                                  <Select
                                    aria-label='Distributor Type'
                                    value={selectDistributorType}
                                    options={getDistributorTypeWithReason()}
                                    onSelect={(value) => updateDistributorType(value)}
                                    className='distributor-dropdown'
                                  />
                                </Grid>
                                <Grid item md={4}>
                                    <br/>
                                    <b>ECM Contract ID</b> (stage only)
                                    <br/>
                                    <TextField
                                        disabled={ecmContractDisabled}
                                        onChange={value => {distributorObject.ecmContractId = value.trim()}}
                                    ></TextField>
                                </Grid>
                                <Grid item md={4}>
                                  <br/>
                                  <b>Distributor Options</b>
                                  <br/>
                                  <Checkbox value="accessCbx" defaultChecked={accessCbxSelected} checked={accessCbxSelected} disabled={accessCbxDisabled} onChange={setAccessCbxSelected}>Grant Portal Access to Contact</Checkbox>
                                  <br />
                                  <Checkbox value="defaultCbx" defaultChecked={defaultCbxSelected} checked={defaultCbxSelected} disabled={defaultCbxDisabled} onChange={setDefaultCbxSelected}>Create Default Data Set</Checkbox>
                                  <br/>
                                  <Checkbox value="generateCbx" defaultChecked={generateCbxSelected} checked={generateCbxSelected} disabled={generateCbxDisabled} onChange={(checked, e) => {setGenerateCbxSelected(checked);setDistributorIdDisabled(checked)}}>Generate Distributor ID</Checkbox>
                                  {(isWorldWideFlagEnabled()|| isWorldWideFlagDevEnabled()) && (
                                        <>
                                            <br/>
                                            <Checkbox value="globalDistributorSelected" checked={globalDistributorSelected} onChange={setGlobalDistributorSelected}>Enable Global Sales</Checkbox>
                                            <br/>
                                        </>
                                    )}
                                    {(isTransferLGAFlagEnabled()|| isTransferLGAFlagDevEnabled()) && (
                                        <>
                                        <Checkbox value="lgaDistributorSelected" checked={lgaDistributorSelected} onChange={setLgaDistributorSelected}>Enable LGA for Distributor</Checkbox>
                                        <br/>
                                        </>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid md={10}>
                            <b>Contact Information</b>
                            <br/>
                            <i><Link onClick={() => setIsModifyContactsOpen(!isModifyContactsOpen)}>Click here to add/remove contacts</Link></i>
                            <i> { (distributorObject.contacts.length == 1 && distributorObject.contacts[0].firstName != "") ? <div> {distributorObject.contacts.length} Contact</div> : (distributorObject.contacts[0].firstName == "") ? <div>0 Contacts.</div> : <div>{distributorObject.contacts.length} Contacts.</div>}</i>
                            </Grid>
                            <Grid container md={11} >
                              <Grid item xs={4}>
                                  <b>Select Default Address</b>
                                  <br />
                                  <br />
                                  <Select
                                      aria-label='Default Address'
                                      value={selectDefaultAddress}
                                      options={getDefaultAddressOptionsWithReason()}
                                      onSelect={(value) => updateDefaultAddress(value)}
                                  />
                              </Grid>
                              <Grid item xs={4}>
                                  <b>Currency</b>
                                  <br/>
                                  Price Region
                                  <br />
                                  <Select
                                      aria-label='Price Region'
                                      value={selectPriceRegion}
                                      disabled={isDefault}
                                      options={getPriceRegionOptionsWithReason()}
                                      onSelect={(value) => updatePriceRegion(value)}
                                  />
                                  <br />
                              </Grid>
                              <Grid item xs={4}>
                                  <br/>
                                  Currency
                                  <br />
                                  <Select
                                      aria-label='Currency'
                                      value={selectCurrency}
                                      disabled={isDefault}
                                      options={getCurrencyOptionsWithReason(selectPriceRegion)}
                                      onSelect={(value) => updateCurrency(value)}
                                  />
                                  <br />
                              </Grid>
                            </Grid>
                            <Grid md={10}>
                                <AddressForm
                                    address={distributorObject.address}
                                    checkValid={distributorObject.checkValid}
                                    isDefault={isDefault}
                                    isCountriesDisabled={isCountriesDisabled}
                                    countries={reducedCountryList}
                                    regions={reducedRegionList}
                                    postalCodeRegex={postalCodeRegex}
                                    updateAddressState={ (address) => updateAddressState(address)}
                                    updateValidAddress={ (isAddressInvalid) => updateValidAddress(isAddressInvalid)}
                                />
                            </Grid>
                            <Grid md={12}>
                                <br />
                                <Button disabled={(isLoadingCreation) || (isAddressInvalid)} onClick={event => handleCheckCreateDistributorId()}>
                                    Create Distributor
                                </Button>
                                {isLoadingCreation ? (
                                    <CircularProgress size={20} />
                                ) : [
                                    loadedCreation ? (
                                        [ creationCompleted ? <CheckIcon className="success-color"/> : <ClearIcon color="secondary" /> ]
                                    ) : null
                                ]}
                                <br/>
                                {creationStatus}
                                <br/>
                            </Grid>
                        </Grid>
                        <Grid item md={3} xs={6} spacing={3}>
                            <Heading variant='subtitle2'>STEP 5: Update Postman Environment</Heading>
                            <Heading variant='subtitle3'>Only for CPAPI access</Heading>
                            <i>Update Postman Environment</i>
                            <br />
                            <br/>
                            <b>Distributor Environment</b>
                            <br />
                            <Select
                              defaultValue={selectCreationDestination}
                              value={selectCreationDestination}
                              options={getCreationDestinationWithReasonByEnv(getEnvironment())}
                              onSelect={(value) => updateCreationDestination(value)}
                              className='distributor-dropdown'
                            />
                            <br/>
                            <br/>
                            <b>Distributor Name</b>
                            <br />
                            <TextField
                                className="partner-name-textfield"
                                onChange= {value => {setNewPartnerName(value.trim()); distributorObject.name = value.trim()}}
                                value={newPartnerName}
                                validationState={getValidationState(distributorObject.checkValid, distributorObject.name)}
                            ></TextField>
                            <br />
                            <br />
                            <b>Distributor ID</b>
                            <br />
                            <TextField
                                className="distributor-id-textfield"
                                onChange= {value => {setNewDistributorId(value.trim()); distributorObject.accountId = value.trim()}}
                                value={newDistributorId}
                                validationState={getValidationState(distributorObject.checkValid, distributorObject.accountId)}
                                disabled={distributorIdDisabled}
                            ></TextField>
                            <br />
                            <br />
                            <b>Postman Environment File</b>
                            <br />
                            <input key={resetPostmanEnvKey} type="file" onChange={ event => handlePostmanEnvChange(event)} />
                            <br />
                            <br />
                            <Button disabled={uploadEnvironment} onClick={event => handleUpdatePostmanEnvironment()}>
                                Update Postman Environment
                            </Button>
                            <br/>
                            <br/>
                            <Button disabled={downloadEnvironment} onClick={event => handleDownloadNewPostmanEnvironment()}>
                                Download New Postman Environment
                            </Button>
                            <br />
                            <br />
                        </Grid>

                    </Grid>
                </div>
                }
            </div>
            <br />
        </div>
    );

    return <div>{checkIfAdobeUser() ? ValidatedApp : <AccessDeniedComponent isLoading={isLoading} />}</div>;
}


export default CreateDistributorPage;
