import {getAllDistributors, getSelectedDistributor} from '../../service/ApiService';
import {IMSAccountService} from '../../service/ims/IMSAccountService';
import ThreeYCAction from "../../constants/ThreeYCAction";
import ThreeYCStatus from "../../constants/ThreeYCStatus";
import {DetailRecordObject, BenefitObject} from "../../RecordsPage/models/ResourceModels";

export function getCurrentUserDistributorInfo() {
  return {
    email: IMSAccountService.getUserProfile().email,
    allDistributors: getAllDistributors(),
    selectedDistributor: getSelectedDistributor()
  };
}

export const getThreeYCAction = (response: DetailRecordObject): string => {
  let committedThreeYCStatus : string | undefined = '';
  let requestedThreeYCStatus : string | undefined = '';
  let recommitmentThreeYCStatus : string | undefined = '';
  let threeYCAction = '';

  if("benefits" in response) {
    let threeYCBenefits: BenefitObject = (response.benefits as { [id: string]: BenefitObject })['THREE_YEAR_COMMIT'];
    if (threeYCBenefits) {
      committedThreeYCStatus = threeYCBenefits.commitment?.status;
      requestedThreeYCStatus = threeYCBenefits.commitmentRequest?.status;
      recommitmentThreeYCStatus = threeYCBenefits.recommitmentRequest?.status;
    }
  }
  let isCustomerThreeYCEligible = getIsCustomerThreeYCEligible(response);
  if (committedThreeYCStatus === ThreeYCStatus.COMMITTED) {
    if (requestedThreeYCStatus === ThreeYCStatus.REQUESTED) {
      threeYCAction = ThreeYCAction.ACCEPT_3YC_UPDATE;
    } else if (recommitmentThreeYCStatus === ThreeYCStatus.REQUESTED) {
      threeYCAction = ThreeYCAction.ACCEPT_3YC_RECOMMITMENT;
    } else {
      threeYCAction = ThreeYCAction.EDIT_3YC_TERMS;
    }
  } else {
    if (requestedThreeYCStatus === ThreeYCStatus.REQUESTED) {
      threeYCAction = ThreeYCAction.ACCEPT_3YC_COMMITMENT;
    } else {
      if (isCustomerThreeYCEligible && !('linkedAccountGroup' in response)) {
        threeYCAction = ThreeYCAction.ENROLL_3YC;
      }
    }
  }
  return threeYCAction;
}

const getIsCustomerThreeYCEligible = (response: DetailRecordObject): boolean => {
  let isThreeYCEligible: boolean;
  let licenseDiscountLevel =0;
  if( "discounts" in response) {
    let n = response.discounts.length;
    for (let i = 0; i < n; i++) {
      if (response.discounts[i].offerType === "LICENSE") {
        licenseDiscountLevel = parseInt(response.discounts[i].level);
      }
    }
  }
  let isCustomerAlreadyEnrolled: boolean = false;
  let isCustomerLga: boolean = false;

  if("benefits" in response){
    let threeYCBenefits : BenefitObject = (response.benefits as {[id: string]: BenefitObject})['THREE_YEAR_COMMIT'];
    let lgaBenefits : BenefitObject = (response.benefits as {[id: string]: BenefitObject})['LARGE_GOVERNMENT_AGENCY'];
    if(threeYCBenefits) isCustomerAlreadyEnrolled = "commitmentRequest" in threeYCBenefits || "commitment" in threeYCBenefits;
    if(lgaBenefits) isCustomerLga = true;
  }
  isThreeYCEligible = (response.cotermDate >= formatDate(new Date())) && licenseDiscountLevel >= 2 && !isCustomerAlreadyEnrolled && !isCustomerLga;
  return isThreeYCEligible;
}

export const formatDate = (today: Date): string => {
  let dd = String(today.getDate()).padStart(2, '0');
  let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  let yyyy = today.getFullYear();
  return yyyy + '-' + mm + '-' + dd;
};

export const getMarketSegmentOptions = () => {
  return [
    {
      label: 'COM',
      value: 'COM'
    },
    {
      label: 'GOV',
      value: 'GOV'
    },
    {
      label: 'EDU',
      value: 'EDU'
    },
  ]
};

export const getOrgType = () => {
  return [
    {
      label: 'STANDARD',
      value: 'STANDARD'
    },
    {
      label: 'CONSORTIUM',
      value: 'CONSORTIUM'
    },
  ]
};

export const getRenewalStatusOptions = () => {
  return [
    {
      label: 'FULL',
      value: 'FULL'
    },
    {
      label: 'NO',
      value: 'No'
    },
    {
      label: 'PARTIAL',
      value: 'PARTIAL'
    },
  ]
};

export const getSubSegmentOptions = (marketSegment: string, marketSubSegments: string[]) => {

  if (marketSegment === 'EDU') {
    return [
      {
        label: 'NON PROFIT',
        value: 'NON_PROFIT',
        disabled: !marketSubSegments.includes('NON_PROFIT')
            && Boolean(marketSubSegments.length)
            && (marketSubSegments.includes('HIGHER_ED') || marketSubSegments.includes('K_12'))
      },
      {
        label: 'HIGHER EDUCATION',
        value: 'HIGHER_ED',
        disabled: marketSubSegments.includes('NON_PROFIT')
      },
      {
        label: 'K 12',
        value: 'K_12',
        disabled: marketSubSegments.includes('NON_PROFIT')
      }
    ]
  } else if (marketSegment === 'GOV') {
    return [
      {
        label: 'STATE',
        value: 'STATE',
        disabled: marketSubSegments.includes('FEDERAL')
      },
      {
        label: 'FEDERAL',
        value: 'FEDERAL',
        disabled: marketSubSegments.includes('STATE')
      }
    ]
  } else {
    return []
  }
}