import React from 'react';

// UI library components
import Heading from '@react/react-spectrum/Heading';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@react/react-spectrum/Button';
import { Grid } from '@material-ui/core';
import SelectList from '@react/react-spectrum/SelectList';
import Dialog from '@react/react-spectrum/Dialog';
// import Header from '@react/react-spectrum/Header';
import TextField from '@react/react-spectrum/Textfield';
import FileCopyOutlined from '@material-ui/icons/FileCopyOutlined';
import {
  IconButton
} from '@material-ui/core';
// Custom styles and utils
import './ManageMembersPage.css';
import { removeDistributorUsers, getDistributorUsers, requestAccess, Distributor, getDistributors, getDistributorDetails, updateDistributorDetails } from '../../../providers/DistributorsAPI';
import UserInfo from '../../../models/UserInfo';
import { getCurrentUserDistributorInfo } from '../../../utils/Functions/utils'
import { getAllDistributors, getSelectedDistributor } from '../../../service/ApiService';
import { IMSAccountService } from '../../../service/ims/IMSAccountService';
import { DetailRecordObject, BenefitObject } from '../../../RecordsPage/models/ResourceModels';
import distributorAttributes from "../../../constants/DistributorAttributes";
import { isWorldWideFlagDevEnabled, isWorldWideFlagEnabled, isTransferLGAFlagDevEnabled, isTransferLGAFlagEnabled } from '../../../config/FeatureFlags';

interface UserAccessRequest {
  emails: string[];
  distributor: Distributor;
}

function getEmptyDistributor(): Distributor {
  return {
    distributorName: '',
    distributorId: '',
    imsOrgId: ''
  };
}

function getEmptyUserAccessRequest(): UserAccessRequest {
  return {
    emails: [],
    distributor: getEmptyDistributor()
  };
}

function ManageMembersPage() {
  var [distributors, setDistributors] = React.useState<Distributor[]>([]);
  var [currentEmail, setCurrentEmail] = React.useState("")
  const [successfulRequest, setSuccessfulRequest] = React.useState(false);
  const [showRequestDialog, setShowRequestDialog] = React.useState(false);
  const [currGlobalSalesEnabled, setCurrGlobalSalesEnabled] = React.useState(false);
  const [currLgaEnabled, setCurrLgaEnabled] = React.useState(false);
  const [removeUserRequest, setRemoveUserRequest] = React.useState<UserAccessRequest>(getEmptyUserAccessRequest());
  const [addUserRequest, setAddUserRequest] = React.useState<UserAccessRequest>(getEmptyUserAccessRequest());
  const [showAddUserDialog, setShowAddUserDialog] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [emails, setEmails] = React.useState<string[]>([]);

  const [currentUserInfo, setCurrentUsernfo] = React.useState<UserInfo | undefined>();

  React.useEffect(() => {
    setCurrentUsernfo(getCurrentUserDistributorInfo())

    setDistributors(getAllDistributors());
    setCurrentEmail(IMSAccountService.getUserProfile().email)
    removeUserRequest.distributor = getSelectedDistributor()
    handleGetDistributorMembers(getSelectedDistributor())
  }, []);

  const distributorOptions = distributors.map(distributor => {
    return {
      label: distributor.distributorName,
      value: JSON.stringify(distributor)
    };
  });

  const emailOptions = emails.map(email => {
    return {
      label: email,
      value: email
    };
  });

  const handleGetDistributorMembers = (distributor: Distributor) => {
    removeUserRequest.emails = []
    setRemoveUserRequest({ ...removeUserRequest, distributor: distributor });
    setAddUserRequest({ ...addUserRequest, distributor: distributor})
    getDistributorDetails(distributor.distributorId).then((response: DetailRecordObject)  => {
      if(isWorldWideFlagEnabled() || isWorldWideFlagDevEnabled()){
        if(response.globalSalesEnabled) setCurrGlobalSalesEnabled(response.globalSalesEnabled)
      }
      if(isTransferLGAFlagEnabled() || isTransferLGAFlagDevEnabled()){
        if(response.isLgaEnabled) setCurrLgaEnabled(response.isLgaEnabled);
      }
    })

    getDistributorUsers(distributor.distributorId).then((response: string[])  => {
      var index = response.indexOf(currentEmail);
      if (index >= 0) {
        response.splice( index, 1 );
      }
      setEmails(response)
    })
  }

  const handleDistributorUpdate = async (fieldName: string, enabled: boolean, distributor: Distributor) => {
    try {
      const response = await updateDistributorDetails(fieldName, enabled, distributor.distributorId, currLgaEnabled, currGlobalSalesEnabled);
      if (response && response.globalSalesEnabled !== null) setCurrGlobalSalesEnabled(response.globalSalesEnabled);
      if (response && response.isLgaEnabled !== null) setCurrLgaEnabled(response.isLgaEnabled);
    } catch (error) {
      console.error("Update failed:", error);
    }
  };

  const handleAccessRequests = () => {
    setIsLoading(true);

    let userAccessRequestBody = {
      currentEmail: currentEmail,
      emails: removeUserRequest.emails,
      distributor: removeUserRequest.distributor
    };
    let userAccessBodyJson = JSON.stringify(userAccessRequestBody);
    removeDistributorUsers(userAccessBodyJson, removeUserRequest.distributor.distributorId).then((response: boolean) => {
      handleGetDistributorMembers(removeUserRequest.distributor)
      setIsLoading(false);
      setShowRequestDialog(true);
      setSuccessfulRequest(response);
      setRemoveUserRequest({ ...removeUserRequest, emails: []});
    });
  };

  const handleAddUserRequest = () => {
    setShowAddUserDialog(false);
    setIsLoading(true);

    let userAccessRequestBody = {
      emails: addUserRequest.emails.filter(Boolean),
      distributors: [addUserRequest.distributor]
    };
    let userAccessBodyJson = JSON.stringify(userAccessRequestBody);
    
    requestAccess(userAccessBodyJson, addUserRequest.distributor.distributorId).then((response: boolean) => {
      handleGetDistributorMembers(addUserRequest.distributor);
      setIsLoading(false);
      setShowRequestDialog(true);
      setSuccessfulRequest(response);
      setAddUserRequest({ ...addUserRequest, emails: []});
    });
  };

  return (
    <div>
      <Dialog
        title='User Modification Request'
        open={showRequestDialog}
        confirmLabel='OK'
        onConfirm={event => setShowRequestDialog(false)}
      >
        {successfulRequest ? 'Portal requests were successfully completed' : 'Failure'}
      </Dialog>

      <Dialog
        title='Add Additional Users'
        open={showAddUserDialog}
        confirmLabel='Submit'
        cancelLabel='Cancel'
        onConfirm={event => handleAddUserRequest()}
        onCancel={() => setShowAddUserDialog(false)}
      >
        <p>Distributor: {addUserRequest.distributor.distributorName}</p>
        <p>Please Enter Email Addresses of users (comma-separated)</p>
        <TextField
            multiLine
            className="add-emails-textfield"
            value={addUserRequest.emails.join(",\n")}
            onChange= { value => setAddUserRequest({...addUserRequest, emails : value.split(",").map(item => item.trim())})}
            placeholder="Add Emails of Users to add"
        ></TextField>
      </Dialog>
      
      {(isWorldWideFlagEnabled()|| isWorldWideFlagDevEnabled()) && (
        <>
          <div className='distributor-select-heading-container'>
            <Heading variant='pageTitle'>Enable/Disable Global Sales for Direct Partner</Heading>
            <div>
              <Button variant='cta' disabled={currGlobalSalesEnabled} onClick={event=> {
                handleDistributorUpdate(distributorAttributes.globalSalesEnabled, true, addUserRequest.distributor || removeUserRequest.distributor)
              }}
                >
                Enable Global Sales
              </Button>
              {isLoading ? (
              <CircularProgress size={20} />
              ) : (
              <Button variant='cta' disabled={!currGlobalSalesEnabled} onClick={event=> {
                handleDistributorUpdate(distributorAttributes.globalSalesEnabled, false, addUserRequest.distributor || removeUserRequest.distributor)                }}
                >
                Disable Global Sales
              </Button>
              )}
            </div>
          </div>
        
        </>
      )}

      {(isTransferLGAFlagEnabled()|| isTransferLGAFlagDevEnabled()) && (
        <>
          <div className='distributor-select-heading-container'>
            <Heading variant='pageTitle'>Enable LGA Customers for Direct Partner</Heading>
            <div>
              <Button variant='cta' disabled={currLgaEnabled} onClick={event=> {
                handleDistributorUpdate(distributorAttributes.isLgaEnabled, true, addUserRequest.distributor || removeUserRequest.distributor)
              }}
              >
                Enable LGA for Distributor
              </Button>
            </div>
          </div>
        </>
      )}

      <div className='distributor-select-heading-container'>
        <Heading variant='pageTitle'>Manage Partner Portal Users</Heading>
        <div>
          <Button variant='cta' disabled={removeUserRequest.distributor==getEmptyDistributor()} onClick={event => setShowAddUserDialog(true)}>Add User(s)</Button>
          {isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <Button variant='cta' disabled={removeUserRequest.emails.length==0} onClick={event => handleAccessRequests()}>
              Remove User(s)
            </Button>
          )}
        </div>
      </div>
      <Heading variant='subtitle3'>View, add, and remove users for your direct partners.</Heading>
      <Heading variant='subtitle3'>Note that the list of users is only for access to this portal, not for any credentials.</Heading>
      <br/>

      <Grid container spacing={3}>
        <Grid item md={4} xs={12}>
          <div>
          <b>Current Distributor Data:</b>
          <br/>
          <span>
            <i>Name: {currentUserInfo?.selectedDistributor.distributorName}</i> 
            <IconButton className="copy-icon" onClick={() => {
                      navigator.clipboard.writeText(String(currentUserInfo?.selectedDistributor.distributorName));
                    }}>
            <FileCopyOutlined className='tiny-icon' style={{fontSize: '1rem'}} ></FileCopyOutlined>
            </IconButton>
          </span>
          <br/>
          <span>
            <i>Distributor ID: {currentUserInfo?.selectedDistributor.distributorId}</i> 
            <IconButton className="copy-icon" onClick={() => {
                      navigator.clipboard.writeText(String(currentUserInfo?.selectedDistributor.distributorId));
                    }}>
              <FileCopyOutlined className='tiny-icon'  style={{fontSize: '1rem'}} ></FileCopyOutlined>
            </IconButton>
          </span>
          <br/>
          <span>
            <i>IMS Org ID: {currentUserInfo?.selectedDistributor.imsOrgId}</i> 
            <IconButton className="copy-icon" onClick={() => {
                      navigator.clipboard.writeText(String(currentUserInfo?.selectedDistributor.imsOrgId));
                    }}>
              <FileCopyOutlined className='tiny-icon'  style={{fontSize: '1rem'}} ></FileCopyOutlined>
            </IconButton>
          </span>
        </div>
        <br/>
          <Heading variant='subtitle2'>Select a Distributor:</Heading>
          <SelectList
            value={JSON.stringify(removeUserRequest.distributor)}
            aria-label='Your Distributors'
            options={distributorOptions}
            className='distributor-select-list'
            onChange={value  => {
              var distributor = JSON.parse(value.toString()) as Distributor
              handleGetDistributorMembers(distributor)
            }}
          />
        </Grid>
        <Grid item md={8} xs={12}>
        {removeUserRequest.distributor ?
          <div>
          <b>Selected Distributor Data:</b>
          <br/>
          <span>
            <i>Name: {removeUserRequest.distributor.distributorName}</i> 
            <IconButton className="copy-icon" onClick={() => {
                      navigator.clipboard.writeText(String(removeUserRequest.distributor.distributorName));
                    }}>
            <FileCopyOutlined className='tiny-icon' style={{fontSize: '1rem'}} ></FileCopyOutlined>
            </IconButton>
          </span>
          <br/>
          <span>
            <i>Distributor ID: {removeUserRequest.distributor.distributorId}</i> 
            <IconButton className="copy-icon" onClick={() => {
                      navigator.clipboard.writeText(String(removeUserRequest.distributor.distributorId));
                    }}>
              <FileCopyOutlined className='tiny-icon' style={{fontSize: '1rem'}} ></FileCopyOutlined>
            </IconButton>
          </span>
          <br/>
          <span>
            <i>IMS Org ID: {removeUserRequest.distributor.imsOrgId}</i> 
            <IconButton className="copy-icon" onClick={() => {
                      navigator.clipboard.writeText(String(removeUserRequest.distributor.imsOrgId));
                    }}>
              <FileCopyOutlined className='tiny-icon' style={{fontSize: '1rem'}} ></FileCopyOutlined>
            </IconButton>
          </span>
          </div>
  
           : null}
          <br/>
          <Heading variant='subtitle2'>Email Addresses Associated with {removeUserRequest.distributor.distributorName}</Heading>
          <div>
            {emails.length == 0 ? (
              "There are no users to modify in this distributor."
            ) : (
            <SelectList
              multiple
              value={removeUserRequest.emails}
              aria-label={ removeUserRequest.distributor.distributorName + 'Users' }
              options={emailOptions}
              className='distributor-select-list'
              onChange={value  => {
                if (value instanceof Array) {
                  setRemoveUserRequest({ ...removeUserRequest, emails: value });
                }
              }}
            />
            )}
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default ManageMembersPage;
