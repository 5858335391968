export const sortByReasons : { [id: string]: string } = {
    'order_id': 'Order ID',
    'created_at': 'Created Date',
    'id': 'ID',
    'created_date': 'Created Date',
    'status': "Status",
    'name': "Name"
  };

  export const sortBy: { [id: string]: string[] } = {
    'resellers': ['created_at','id', 'status', 'name'],
    'customers': ['created_at', 'id', 'status', 'name'],
    'orders': ['created_at','order_id'], // status
    'transfers': ['created_date', 'id', 'status'],
    'retailorders': ['created_date','order_id'],
    'retailresellers': ['created_date','id', 'status', 'name'],
    'retailcustomers': ['created_date', 'id', 'status']
  };

export const orderByReasons: { [id: string]: string } = {
    'asc': 'Ascending',
    'desc': 'Descending'
  };

  export const pageSizeOptions: { [id: string]: string } = {
    '10': '10',
    '20': '20',
    '30': '30',
    '50': '50',
    '100': '100'
  };

  export const filterOptions: { [id: string]: string } = {
    'id': 'ID',
    'membership_id': 'Membership ID',
    'reseller_id': 'Reseller ID',
    'customer_id': 'Customer ID',
  };

  export const contactReasons: { [id: string]: string } = {
    'discrepency': 'Incorrect Info',
    'creation': 'Creation Issue',
    'vip_membership': "VIP Membership Issue",
    'transfer_failure': "Transfer Failure", 
    'order_failure': "Order Failure", 
    'api': "API Error",
    'other': "Other"
  };

  export const contact: { [id: string]: string[] } = {
    // 'records': ['vip_membership','creation', 'discrepency', 'other'],
    // 'memberships': ['vip_membership', 'creation', 'discrepency', 'other'],
    // 'offers': ['created_at','order_id','other'], 
  };

  export const vipSKUOptions: { [id: string]: string } = {
    'none': 'None',
    '65324055BA01A12': '65324055BA01A12',
    '65291026BA01B12': '65291026BA01B12',
    '65304043BA01B12': '65304043BA01B12',
    '65304040BA01B12': '65304040BA01B12',
    '65271309BA12A12': '65271309BA12A12',
    '65310110BA12A12': '65310110BA12A12',
    '65297935BA12A12': '65297935BA12A12',
    '65310110BA12B12': '65310110BA12B12',
  };

  // LGA Enabled Offers
export const vipSKUOptionsForLGA: { [id: string]: string } = {
  'none': 'None',
  '30005870BC06A12': '30005870BC06A12',
  '65320955BC06A12': '65320955BC06A12',
  '30005864BC07A12': '30005864BC07A12',
  '65305615BC08A12': '65305615BC08A12',
  '65309784BC09A12': '65309784BC09A12',
};

  export const vipmpSKUOptions: { [id: string]: string } = {
    'none': '',
    '65324055BA01A12': '65304520CA01A12',
    '65291026BA01B12': '65322658CA01A12',
    '65304043BA01B12': '65304578CA01A12',
    '65304040BA01B12': '65305159CA01A12',
    '65271309BA12A12': '65322651CA12A12', // acro pro dc for ent.
    '65310110BA12A12': '65325063CA01A12', // cc all apps ent. (extended term)
    '65310110BA12B12': '65325063CA01A12', // cc all apps ent.
    '65297935BA12A12': '65304520CA01A12', // acro pro dc for teams
    '30005870BC06A12': '30005900CC06A12', // LGA Offers with discount 06
    '65320955BC06A12': '30003058CC06A12', // LGA Offers with discount 06
    '30005864BC07A12': '30005900CC07A12', // LGA Offers with discount 07
    '65305615BC08A12': '30006442CC08A12', // LGA Offers with discount 08
    '65309784BC09A12': '30002160CC09A12' // LGA Offers with discount 09
  };

  