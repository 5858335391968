import { API_ROOT } from '../config/ApiConfig';
import { generateRequestHeaders } from '../service/ApiService';
import { DistributorRequest } from '../models/distributors/DistributorRequest';
import { DetailRecordObject } from '../RecordsPage/models/ResourceModels';
import distributorAttributes from "../constants/DistributorAttributes";

export interface Distributor {
  distributorId: string;
  imsOrgId: string;
  distributorName: string;
  programType: string;
}

export async function getDistributorUsers(distributorId: string): Promise<string[]> {
  let response = await fetch(`${API_ROOT}/distributors/${distributorId}/users`, {
    headers: generateRequestHeaders()
  });
  let data = await response.json();
  return data;
}

export async function getDistributorDe(distributorId: string): Promise<string[]> {
  let response = await fetch(`${API_ROOT}/distributors/${distributorId}/users`, {
    headers: generateRequestHeaders()
  });
  let data = await response.json();
  return data;
}

export async function removeDistributorUsers(json: string, distributorId: string): Promise<any> {
  let endpoint = `${API_ROOT}/distributors/${distributorId}/users/remove`;
  let response = await fetch(endpoint, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: json
  });
  let data = await response.json();
  return data;
}

export async function getDistributorDetails(distributorId: string): Promise<DetailRecordObject> {
  let response = await fetch(`${API_ROOT}/distributors/${distributorId}`, {
    headers: generateRequestHeaders()
  });
  let data = await response.json();
  return data;
}

export async function getDistributors(): Promise<Distributor[]> {
  let response = await fetch(`${API_ROOT}/distributors`, {
    headers: generateRequestHeaders()
  });
  let data = await response.json();
  if (!Array.isArray(data)) {
    throw new Error(`no distributors returned: status: ${response.status}: ${await response.text()}`)
  }
  return data;
}

export async function requestAccess(json: string, distributorId: string): Promise<any> {
  let endpoint = `${API_ROOT}/distributors/${distributorId}/add`;
  let response = await fetch(endpoint, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: json
  });
  let data = await response.json();
  return data.code && data.code === "1124" ? false : true;
}

export async function updateDistributorDetails(fieldName: string, enabled: boolean, distributorId: string, currLgaEnabled: boolean, currGlobalSalesEnabled: boolean): Promise<any> {
  let endpoint = `${API_ROOT}/distributors/${distributorId}`;
  let body: string;
  if (fieldName === distributorAttributes.globalSalesEnabled) {
    body = JSON.stringify({ globalSalesEnabled: enabled,
      isLgaEnabled: currLgaEnabled});
  } else if (fieldName === distributorAttributes.isLgaEnabled) {
    body = JSON.stringify({ isLgaEnabled: enabled,
      globalSalesEnabled: currGlobalSalesEnabled});
  }

  try {
    let response = await fetch(endpoint, {
      headers: generateRequestHeaders(),
      method: 'PATCH',
      body: body
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    let data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
}

export async function provisionNewPartner(newImsOrgId: string): Promise<any> {
  let response = await fetch(`${API_ROOT}/provision`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: newImsOrgId
  });

  var data = {httpstatus: 500};
  data.httpstatus = response.status;

  return data;
}

export async function generateDistributorId(body: DistributorRequest): Promise<any> {
  let response = await fetch(`${API_ROOT}/distributors/generate`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(body)
  });
  let data = await response.json();
  data.httpstatus = response.status;

  return data;
}

export async function requestUserAccess(json: string, distributorId: string): Promise<any> {
  let endpoint = `${API_ROOT}/distributors/${distributorId}/users`;
  let response = await fetch(endpoint, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: json
  });
  let data = await response.json();
  return data;
}

export async function createDistributor(body: DistributorRequest): Promise<any> {
  let finalResult = await fetch(`${API_ROOT}/distributors`, {
    headers: generateRequestHeaders(),
    method: 'POST',
    body: JSON.stringify(body)
  }).then(response =>

    response.json().then(data => ({status: response.status, body: data, rest: response}))
  ).then( data => {

    var result = {httpstatus: 500, completed: false};
    result.completed = data.body
    if(data.status) {
      result.httpstatus = data.status;
    } 

    return result;
  }).catch(function(error) {
    var data = {httpstatus: 500, completed: false};
    return data;
  });

  return finalResult;
}

